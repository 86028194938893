import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
var SERVICE_BASE_URL = '/app-doc-center/v1/project-usage-board';
export default {
  /** 获取字典 */
  getDic: function getDic() {
    return request.get("".concat(SERVICE_BASE_URL, "/getDic"));
  },

  /** 获取项目使用明细 */
  getPrjDetail: function getPrjDetail(params, pageNo, pageSize) {
    return request.post("".concat(SERVICE_BASE_URL, "/getPrjDetail?pageNo=").concat(pageNo, "&pageSize=").concat(pageSize), params);
  },

  /** 获取项目概况 */
  getPrjOverview: function getPrjOverview(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/getPrjOverview"), params);
  },

  /** 获取审核问题概况 项目类型相关 */
  getPrjProblemOverview: function getPrjProblemOverview(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/getPrjProblemOverview"), params);
  },

  /** 获取使用项目地区相关 */
  getRegionPrjOverview: function getRegionPrjOverview(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/getRegionPrjOverview"), params);
  },

  /** 获取审核问题数地区相关 */
  getRegionProblemOverview: function getRegionProblemOverview(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/getRegionProblemOverview"), params);
  },

  /**导出项目概况 */
  getPrjSummaryExcel: function getPrjSummaryExcel(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/exportPrjOverview"), params);
  },

  /**导出审核问题概况 */
  getQuestionSummaryExcel: function getQuestionSummaryExcel(params) {
    return request.post("".concat(SERVICE_BASE_URL, "/exportProblem"), params);
  }
};