var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "groupAnalysis" } },
    [
      _c("div", { staticClass: "header" }, [
        _c(
          "div",
          { staticClass: "search-header" },
          [
            _c(
              "div",
              { staticClass: "search-item" },
              [
                _c("label", { staticClass: "medium" }, [_vm._v("开始时间")]),
                _c("el-date-picker", {
                  attrs: {
                    size: "small",
                    "picker-options": _vm.pickerOptions,
                    editable: false,
                    clearable: false,
                    "value-format": "yyyy-MM-dd",
                    type: "date",
                    placeholder: "请选择开始时间"
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChangeDate(1)
                    }
                  },
                  model: {
                    value: _vm.startDate,
                    callback: function($$v) {
                      _vm.startDate = $$v
                    },
                    expression: "startDate"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-item" },
              [
                _c("label", { staticClass: "medium" }, [_vm._v("结束时间")]),
                _c("el-date-picker", {
                  attrs: {
                    size: "small",
                    "picker-options": _vm.pickerOptions,
                    editable: false,
                    clearable: false,
                    "value-format": "yyyy-MM-dd",
                    type: "date",
                    placeholder: "请选择结束时间"
                  },
                  on: {
                    change: function($event) {
                      return _vm.handleChangeDate(2)
                    }
                  },
                  model: {
                    value: _vm.endDate,
                    callback: function($$v) {
                      _vm.endDate = $$v
                    },
                    expression: "endDate"
                  }
                })
              ],
              1
            ),
            _c(
              "el-button",
              {
                staticStyle: { "margin-right": "15px" },
                attrs: { type: "maiaPrimary", size: "small" },
                on: { click: _vm.openPrj }
              },
              [_vm._v("选择项目")]
            ),
            _c(
              "div",
              { staticClass: "search-item" },
              [
                _c(
                  "el-radio-group",
                  {
                    attrs: { size: "small" },
                    on: { change: _vm.handleRadioClick },
                    model: {
                      value: _vm.dwgOrModel,
                      callback: function($$v) {
                        _vm.dwgOrModel = $$v
                      },
                      expression: "dwgOrModel"
                    }
                  },
                  _vm._l(_vm.dwgOrModelList, function(item, index) {
                    return _c("el-radio-button", {
                      key: index,
                      attrs: {
                        value: index,
                        disabled: _vm.radioBtnDisabled,
                        label: item
                      }
                    })
                  }),
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "date__duration" },
              [
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary", plain: "" },
                    on: {
                      click: function($event) {
                        return _vm.changeDateDuration(1)
                      }
                    }
                  },
                  [_vm._v("最近一个月")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary", plain: "" },
                    on: {
                      click: function($event) {
                        return _vm.changeDateDuration(3)
                      }
                    }
                  },
                  [_vm._v("最近三个月")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { size: "small", type: "primary", plain: "" },
                    on: {
                      click: function($event) {
                        return _vm.changeDateDuration(6)
                      }
                    }
                  },
                  [_vm._v("最近半年")]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "search-item" },
              [
                !_vm.reportingExcelFlag
                  ? _c(
                      "el-button",
                      {
                        attrs: { type: "maiaPrimary", size: "small" },
                        on: { click: _vm.exportGroupExcel }
                      },
                      [_vm._v("导出Excel")]
                    )
                  : _c(
                      "el-button",
                      { attrs: { type: "info", size: "small", disabled: "" } },
                      [_vm._v("Excel生成中...")]
                    )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("div", { staticClass: "container" }, [
        _c(
          "div",
          { staticClass: "data-screening" },
          [
            _c("data-screen", {
              attrs: {
                totalProjectIds: _vm.totalProjectIds,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeQueryFlag: _vm.changeQueryFlag,
                changeInitFlag: _vm.changeInitFlag,
                changeDateFlag: _vm.changeDateFlag,
                AllPrjIdsList: _vm.AllPrjIdsList
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("assessor-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("question-number-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("question-type-and-major", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("drawing-function-statistics", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "chart-item" },
          [
            _c("project-drawing-time", {
              attrs: {
                screenWidth: _vm.screenWidth,
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              },
              on: { openIssueStatistical: _vm.openIssueStatistical }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "list-item" },
          [
            _c("prj-insider-or-supplier", {
              attrs: {
                constructPlansData: _vm.constructPlansData,
                listQuery: _vm.newListQuery,
                dwgOrModelType: _vm.dwgOrModelType,
                changeInitFlag: _vm.changeInitFlag
              }
            })
          ],
          1
        )
      ]),
      _vm.dwgOrModelType === 1
        ? _c("issueStatistical", {
            ref: "issueStatistical",
            attrs: { isNewList: true }
          })
        : _vm.dwgOrModelType === 2
        ? _c("issueStatistical3D", {
            ref: "issueStatistical3D",
            attrs: { isNewList: true }
          })
        : _vm._e(),
      _c(
        "el-dialog",
        {
          staticClass: "allPrjDialog",
          attrs: {
            title: "选择项目",
            visible: _vm.prjDialog,
            width: "20%",
            "before-close": _vm.handleClose
          },
          on: {
            "update:visible": function($event) {
              _vm.prjDialog = $event
            }
          }
        },
        [
          _c("el-input", {
            attrs: { placeholder: "输入项目名进行筛选", clearable: "" },
            on: { clear: _vm.clearTree },
            model: {
              value: _vm.filterText,
              callback: function($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText"
            }
          }),
          _c(
            "el-checkbox",
            {
              on: { change: _vm.handleCheckAllChange },
              model: {
                value: _vm.checkAll,
                callback: function($$v) {
                  _vm.checkAll = $$v
                },
                expression: "checkAll"
              }
            },
            [_vm._v("全选")]
          ),
          _c("el-tree", {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.treeLoading,
                expression: "treeLoading"
              }
            ],
            ref: "allPrjTree",
            staticClass: "channel-tree",
            attrs: {
              data: _vm.prjAllList,
              props: _vm.treeDataProp,
              "show-checkbox": "",
              "node-key": "prjID",
              indent: _vm.indentWidth,
              "check-on-click-node": "true",
              "render-content": _vm.renderContent,
              "default-expanded-keys": _vm.expandedKeys,
              "filter-node-method": _vm.filterNodePrj
            },
            on: { "check-change": _vm.nodeChangeClick }
          }),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.prjDialog = false
                    }
                  }
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.toSearch } },
                [_vm._v("确 定")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }