//
//
//
//
//
//
//
//
//
//
//
//
//
import group from './index.vue';
import project from './project.vue';
export default {
  components: {
    group: group,
    project: project
  },
  data: function data() {
    return {
      currentBoard: 1
    };
  }
};