import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.array.concat.js";
import request from '@/utils/request';
import axios from "axios";
import { getToken } from "@/utils";
export default {
  queryProjectsByIam: function queryProjectsByIam(params) {
    return request.post('/app/v1/project-list/get-prj-by-iam-org', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryDataScreen: function queryDataScreen(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/data-screening', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjStaff: function queryPrjStaff(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  getProfessionTree: function getProfessionTree(templateType) {
    return request.get("/app-doc-center/v1/doc-statistics/get-profession-tree?templateType=".concat(templateType)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryFolderSpecs: function queryFolderSpecs(folderOldIds) {
    return request.get("/app-doc-center/v1/gml/drawing/analyse-new/query-folder-specs?folderOldIds=".concat(folderOldIds)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryQueTypeByBuId: function queryQueTypeByBuId(buId) {
    return request.get("/app-doc-center/v1/gml/drawing/analyse-new/query-buid-que-type?buid=".concat(buId)).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjProblemNum: function queryPrjProblemNum(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-problem-num-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemTypeNum: function queryProblemTypeNum(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/problem-quetype-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemSpecNum: function queryProblemSpecNum(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/problem-spec-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemFunctionStaff: function queryProblemFunctionStaff(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/problem-function-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjUserAvgDrawing: function queryPrjUserAvgDrawing(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-user-avg-drawing-time', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryInternalExternalRate: function queryInternalExternalRate(params) {
    return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-insider-external-personnel', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjInternalUser: function queryPrjInternalUser(pageNo, pageSize, params) {
    return request.post("/app-doc-center/v1/gml/drawing/analyse-new/prj-internal-user?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize), params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjExternalUser: function queryPrjExternalUser(pageNo, pageSize, params) {
    return request.post("/app-doc-center/v1/gml/drawing/analyse-new/prj-external-user?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize), params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportGroupExcel: function exportGroupExcel(params) {
    return request({
      url: '/app-doc-center/v1/gml/drawing/analyse-new/export-group-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportAssessorExcel: function exportAssessorExcel(params) {
    return request({
      url: '/app-doc-center/v1/gml/drawing/analyse-new/exprot-prjuser-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportIssueNumExcel: function exportIssueNumExcel(params) {
    return request({
      url: '/app-doc-center/v1/gml/drawing/analyse-new/exprot-prjQueType-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportFuncExcel: function exportFuncExcel(params) {
    return request({
      url: '/app-doc-center/v1/gml/drawing/analyse-new/exprot-prjFunction-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  queryDataScreenThree: function queryDataScreenThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/dataScreening', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  querySpecsAndTypeThree: function querySpecsAndTypeThree() {
    return request.get("/app-doc-center/v1/gml/qm-drawing-kanban/initDic").then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjProblemNumThree: function queryPrjProblemNumThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/prj-problem-num-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjStaffThree: function queryPrjStaffThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/prj-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemTypeNumThree: function queryProblemTypeNumThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/problem-quetype-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemSpecNumThree: function queryProblemSpecNumThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/problem-spec-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryInternalExternalRateThree: function queryInternalExternalRateThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/queryUserScale', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjInternalUserThree: function queryPrjInternalUserThree(pageNo, pageSize, params) {
    return request.post("/app-doc-center/v1/gml/qm-drawing-kanban/prj-internal-user?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize), params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjExternalUserThree: function queryPrjExternalUserThree(pageNo, pageSize, params) {
    return request.post("/app-doc-center/v1/gml/qm-drawing-kanban/prj-external-user?pageNo=".concat(pageNo, "&pageSize=").concat(pageSize), params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryProblemFunctionStaffThree: function queryProblemFunctionStaffThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/problem-function-staff', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  queryPrjUserAvgDrawingThree: function queryPrjUserAvgDrawingThree(params) {
    return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/prj-user-avg-drawing-time', params).then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  },
  exportGroupExcelThree: function exportGroupExcelThree(params) {
    return request({
      url: '/app-doc-center/v1/gml/qm-drawing-kanban/export-group-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportAssessorExcelThree: function exportAssessorExcelThree(params) {
    return request({
      url: '/app-doc-center/v1/gml/qm-drawing-kanban/exprot-prjuser-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportIssueNumExcelThree: function exportIssueNumExcelThree(params) {
    return request({
      url: '/app-doc-center/v1/gml/qm-drawing-kanban/exprot-prjQueType-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },
  exportFuncExcelThree: function exportFuncExcelThree(params) {
    return request({
      url: '/app-doc-center/v1/gml/qm-drawing-kanban/exprot-prjFunction-excel',
      method: 'post',
      responseType: "blob",
      data: params
    });
  },

  /**
   * 项目人员统计 [2D/3D] 导出excel
   * @param dim 维度 2D/3D
   * @param params 当前查询参数
   */
  exportInternalUserExcel: function exportInternalUserExcel(dim, params) {
    if (dim == '1') {
      // 2D导出
      return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-internal-user-export-excel', params);
    } else {
      // 3D导出
      return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/prj-internal-user-export-excel', params);
    }
  },

  /**
   * 分供方人员统计 [2D/3D] 导出excel
   * @param dim 维度 2D/3D
   * @param params 当前查询参数
   */
  exportExternalUserExcel: function exportExternalUserExcel(dim, params) {
    if (dim == '1') {
      return request.post('/app-doc-center/v1/gml/drawing/analyse-new/prj-external-user-export-excel', params);
    } else {
      return request.post('/app-doc-center/v1/gml/qm-drawing-kanban/prj-external-user-export-excel', params);
    }
  },
  //字典接口 用来 参审人员数量分布统计 的图例排序
  drawingAnalyseNewUserFunctions: function drawingAnalyseNewUserFunctions() {
    return request.get('/app-doc-center/v1/gml/drawing/analyse-new/user-functions').then(function (res) {
      return res;
    }).catch(function (err) {
      return Promise.reject(err);
    });
  }
};