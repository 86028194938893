var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "data_board" }, [
    _c("div", { staticClass: "header" }, [
      _c(
        "div",
        {
          class: ["board__type", _vm.currentBoard === 1 ? "active" : ""],
          on: {
            click: function($event) {
              $event.stopPropagation()
              _vm.currentBoard = 1
            }
          }
        },
        [_vm._v("集团看板")]
      )
    ]),
    _c(
      "div",
      { staticClass: "body" },
      [
        _vm.currentBoard === 1 ? _c("group") : _vm._e(),
        _vm.currentBoard === 2 ? _c("project") : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }