var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading.root,
          expression: "loading.root"
        }
      ],
      staticClass: "prjuse_board"
    },
    [
      _c(
        "div",
        { staticClass: "select__collector" },
        [
          _c(
            "div",
            { staticClass: "select" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("城市选择:")]),
              _c(
                "el-select",
                {
                  attrs: {
                    size: "small",
                    multiple: "",
                    clearable: "",
                    "collapse-tags": "",
                    placeholder: "全部"
                  },
                  model: {
                    value: _vm.factor.city,
                    callback: function($$v) {
                      _vm.$set(_vm.factor, "city", $$v)
                    },
                    expression: "factor.city"
                  }
                },
                _vm._l(_vm.dict.regionList, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { value: item.regionCode, label: item.regionName }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "select" },
            [
              _c("span", { staticClass: "label" }, [_vm._v("项目时间:")]),
              _c("el-date-picker", {
                attrs: {
                  type: "daterange",
                  size: "small",
                  "value-format": "yyyy-MM-dd HH:mm:ss",
                  "range-separator": "至",
                  "start-placeholder": "开始日期",
                  "end-placeholder": "结束日期"
                },
                model: {
                  value: _vm.factor.prjDate,
                  callback: function($$v) {
                    _vm.$set(_vm.factor, "prjDate", $$v)
                  },
                  expression: "factor.prjDate"
                }
              })
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary", plain: "" },
              on: { click: _vm.reset }
            },
            [_vm._v("重置")]
          ),
          _c(
            "el-button",
            {
              attrs: { size: "small", type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("查询")]
          )
        ],
        1
      ),
      _c("div", { staticClass: "appearance_area" }, [
        _c("div", { staticClass: "appearance" }, [
          _c(
            "div",
            { staticClass: "title" },
            [
              _vm._v(" 项目概况 "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    plain: "",
                    disabled: _vm.prjSummaryLoading
                  },
                  on: { click: _vm.exportPrjSummary }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.prjOverview,
                  expression: "loading.prjOverview"
                }
              ],
              staticClass: "concrete__content"
            },
            [
              _c("div", { ref: "prjOverviewPie", staticClass: "half" }),
              _c("div", { ref: "prjOverviewBar", staticClass: "half" })
            ]
          )
        ]),
        _c("div", { staticClass: "appearance" }, [
          _c(
            "div",
            { staticClass: "title" },
            [
              _vm._v(" 审核问题概况 "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "small",
                    type: "primary",
                    plain: "",
                    disabled: _vm.questionSummaryLoading
                  },
                  on: { click: _vm.exportAuditQuestion }
                },
                [_vm._v("导出")]
              )
            ],
            1
          ),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.prjProblemOverview,
                  expression: "loading.prjProblemOverview"
                }
              ],
              staticClass: "concrete__content"
            },
            [
              _c("div", { ref: "prjProblemOverviewPie", staticClass: "half" }),
              _c("div", { ref: "prjProblemOverviewBar", staticClass: "half" })
            ]
          )
        ]),
        _c("div", { staticClass: "appearance" }, [
          _c("div", { staticClass: "title" }, [_vm._v("使用项目数")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.regionPrjOverview,
                  expression: "loading.regionPrjOverview"
                }
              ],
              staticClass: "concrete__content"
            },
            [_c("div", { ref: "regionPrjOverview", staticClass: "whole" })]
          )
        ]),
        _c("div", { staticClass: "appearance" }, [
          _c("div", { staticClass: "title" }, [_vm._v("审核问题数")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.regionProblemOverview,
                  expression: "loading.regionProblemOverview"
                }
              ],
              staticClass: "concrete__content"
            },
            [_c("div", { ref: "regionProblemOverview", staticClass: "whole" })]
          )
        ]),
        _c("div", { staticClass: "appearance big" }, [
          _c("div", { staticClass: "title" }, [_vm._v("项目使用明细")]),
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading.prjDetail,
                  expression: "loading.prjDetail"
                }
              ],
              staticClass: "concrete__content"
            },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    height: "100%",
                    "header-cell-class-name": "table__header"
                  }
                },
                [
                  _c("el-table-column", {
                    attrs: { label: "所属项目", prop: "prjName" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "所属地区", prop: "region" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "项目问题数", prop: "problemNum" }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "pagation" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.page.pageNo,
                  "page-size": _vm.page.pageSize,
                  layout: "total, prev, pager, next, jumper",
                  total: _vm.page.totalCount
                },
                on: { "current-change": _vm.changePage }
              })
            ],
            1
          )
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }