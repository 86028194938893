var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "questionNumberStatistics" } }, [
    _c(
      "div",
      { staticClass: "echarts" },
      [
        _c("span", { staticClass: "title" }, [_vm._v("问题数量分布统计")]),
        _c(
          "el-select",
          {
            staticStyle: { "margin-right": "10px" },
            attrs: {
              "popper-append-to-body": false,
              size: "small",
              multiple: "",
              "collapse-tags": "",
              placeholder:
                _vm.dwgOrModelType === 1 ? "全部图纸专业" : "全部模型专业"
            },
            on: { change: _vm.handleChangeSpecID },
            model: {
              value: _vm.queStatisticsQuery.specIds,
              callback: function($$v) {
                _vm.$set(_vm.queStatisticsQuery, "specIds", $$v)
              },
              expression: "queStatisticsQuery.specIds"
            }
          },
          [
            _c("el-option", {
              attrs: {
                label:
                  _vm.dwgOrModelType === 1 ? "全部图纸专业" : "全部模型专业",
                value: ""
              }
            }),
            _vm._l(_vm.queSpecsData, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.specName, value: item.specId }
              })
            })
          ],
          2
        ),
        _c(
          "el-select",
          {
            attrs: {
              "popper-append-to-body": false,
              size: "small",
              multiple: "",
              "collapse-tags": "",
              placeholder: "全部问题类型"
            },
            on: { change: _vm.handleChangeQueTypeID },
            model: {
              value: _vm.queStatisticsQuery.queTypeIds,
              callback: function($$v) {
                _vm.$set(_vm.queStatisticsQuery, "queTypeIds", $$v)
              },
              expression: "queStatisticsQuery.queTypeIds"
            }
          },
          [
            _c("el-option", { attrs: { label: "全部问题类型", value: "" } }),
            _vm._l(_vm.queTypesData, function(item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item.cnname, value: item.id }
              })
            })
          ],
          2
        ),
        _c(
          "div",
          { staticClass: "right" },
          [
            _c("label", { staticStyle: { "font-size": "0.8vw" } }, [
              _vm._v("排序方式：")
            ]),
            _c(
              "el-select",
              {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  "popper-append-to-body": false,
                  size: "small",
                  multiple: "",
                  "collapse-tags": "",
                  placeholder: "问题类型"
                },
                on: { change: _vm.handleChangeSortType },
                model: {
                  value: _vm.queStatisticsQuery.sortTypes,
                  callback: function($$v) {
                    _vm.$set(_vm.queStatisticsQuery, "sortTypes", $$v)
                  },
                  expression: "queStatisticsQuery.sortTypes"
                }
              },
              [
                _c("el-option", { attrs: { label: "总数量", value: "" } }),
                _vm._l(_vm.queTypesData, function(item, index) {
                  return _c("el-option", {
                    key: index,
                    attrs: { label: item.cnname, value: item.id }
                  })
                })
              ],
              2
            ),
            !_vm.reportingIssueNumFlag
              ? _c(
                  "el-button",
                  {
                    attrs: { type: "maiaPrimary", size: "small" },
                    on: { click: _vm.exportIssueNumExcel }
                  },
                  [_vm._v("导出Excel")]
                )
              : _c(
                  "el-button",
                  { attrs: { size: "small", type: "info", disabled: "" } },
                  [_vm._v("Excel生成中...")]
                )
          ],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "item-chart",
        attrs: {
          "element-loading-text": "数据加载中，请稍后...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 1)"
        }
      },
      [
        _c("div", { staticClass: "total-num" }, [
          _c("span", [
            _vm._v("问题总人数：" + _vm._s(_vm.questionTotalNum.questionNum))
          ]),
          _c("span", [
            _vm._v("平均问题数：" + _vm._s(_vm.questionTotalNum.questionAvgNum))
          ])
        ]),
        _c("div", { staticClass: "chart", attrs: { id: "queNumChart" } }),
        _vm.prjQueTypeStaffVos.length === 0 || _vm.prjQueTypeStaffVos == null
          ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }