var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "projectDrawingTime" } }, [
    _c("div", { staticClass: "echarts" }, [
      _vm.dwgOrModelType === 1
        ? _c("span", { staticClass: "title" }, [_vm._v("项目人均审图时长")])
        : _vm._e(),
      _vm.dwgOrModelType === 2
        ? _c("span", { staticClass: "title" }, [_vm._v("项目人均模型审核时长")])
        : _vm._e(),
      _c("div", { staticClass: "right" })
    ]),
    _c(
      "div",
      {
        directives: [
          {
            name: "loading",
            rawName: "v-loading",
            value: _vm.loading,
            expression: "loading"
          }
        ],
        staticClass: "item-chart",
        attrs: {
          "element-loading-text": "数据加载中，请稍后...",
          "element-loading-spinner": "el-icon-loading",
          "element-loading-background": "rgba(255, 255, 255, 1)"
        }
      },
      [
        _c("div", {
          staticClass: "chart",
          staticStyle: { height: "100%" },
          attrs: { id: "prjDrawTimeChart" }
        }),
        _vm.prjDrawingTimeList.length === 0 || _vm.prjDrawingTimeList == null
          ? _c("div", { staticClass: "empty" }, [_vm._v("暂无数据")])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }